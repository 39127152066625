<template>
    <section class="content">
        <div class="card">
            <div class="card-header">
                Filter Pencarian
            </div>
            <div class="card-body row" style="padding-bottom:0px">
                <div class="col-md-3 form-group">
                    <label class="control-label">Mapel</label>
                    <input type="hidden" name="row_id" value="" />
                    <input id="mapel" class="form-control mapel" type="text" required="required" name="mapel" />
                </div>
                <div class="col-md-3 form-group">
                    <label class="control-label">Kelas</label>
                    <input id="kelas" class="form-control kelas" type="text" required="required" name="kelas" />
                </div>
                <div class="col-md-3 form-group">
                    <label class="control-label">Materi</label>
                    <input id="materi" class="form-control materi" type="text" required="required" name="materi" />
                </div>
                <div class="col-md-3 form-group">
                    <label class="control-label">Tentor</label>
                    <input id="tentor" class="form-control tentor" type="text" required="required" name="tentor" />
                </div>
            </div>
            <div class="card-footer">
                <button type="submit" v-on:click="search" class="btn btn-primary btn-md float-right">Cari</button>
            </div>
        </div>
        <table class="table table-hover" ref="tableuser">
            <thead>
                <tr>
                    <th>KODE</th>
                    <th>TENTOR</th>
                    <th>MAPEL</th>
                    <th>KELAS</th>
                    <th>HARI</th>
                    <th>TANGGAL</th>
                    <th>PUKUL</th>
                    <th>MATERI</th>
                    <th>UJIAN / LATIHAN</th>
                    <th>KETERANGAN</th>
                </tr>
            </thead>
            <tbody @click="handleClick">
            </tbody>
        </table>
        <div class="modal" tabindex="-1" role="dialog" ref="formDialog" data-backdrop="static">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <form role="form" @submit="submitForm">
                        <div class="modal-header">
                            <h5 class="modal-title">{{ formTitle }}</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <p v-if="errors.length" class="alert alert-danger">
                                <b>Please correct the following error(s):</b>
                                <ul>
                                    <li v-for="error in errors">{{ error }}</li>
                                </ul>
                            </p>
                        <div class="row">
                        <div class="col-md-12">
                            <div class="card card-primary">
                                <div class="card-header">
                                <h3 class="card-title">FORM PLAN MENGAJAR</h3>
                                <div class="card-tools">
                                    <button type="button" class="btn btn-tool" data-card-widget="collapse" title="Collapse">
                                    <i class="fas fa-minus"></i>
                                    </button>
                                </div>
                                </div>
                                <div class="card-body" style="display: block;">
                                <div class="row">
                                <div class="col-md-6 form-group">
                                <label class="control-label">Tentor</label>
                                <v-select :options="optTentor" v-model="form.tentor_id" :reduce="opt => opt.id" />
                                </div>
                                <div class="col-md-6 form-group">
                                    <label class="control-label">Program</label>
                                    <v-select :options="optProgram" v-model="form.program_id" :reduce="opt => opt.id" />
                                </div>
                                <div class="col-md-6 form-group">
                                    <label class="control-label">Mapel</label>
                                    <v-select :options="optMapel" v-model="form.mapel_id" :reduce="opt => opt.id" @input="setSelected" />
                                </div>
                                <div class="col-md-6 form-group">
                                    <label class="control-label">Materi</label>
                                    <!-- <textarea class="form-control" v-model="form.materi" ></textarea> -->
                                    <v-select :options="optMateri" v-model="form.materi_id" :reduce="opt => opt.id" @input="setSelectedMateri" />
                                </div>
                                <div class="col-md-6 form-group">
                                    <label class="control-label">Kelas</label>
                                    <v-select :options="optKelas" v-model="form.kelas_id" :reduce="opt => opt.id" />
                                </div>
                                <div class="col-md-6 form-group">
                                    <label class="control-label">Tanggal</label>
                                    <datepicker v-model="form.tanggal" />
                                </div>
                                <div class="row" style="padding: 7.5px;">
                                    <div class="col-md-6 form-group">
                                        <label class="control-label">Mulai</label>
                                        <timepicker  v-model="form.time_start" />
                                    </div>
                                    <div class="col-md-6 form-group">
                                        <label class="control-label">Selesai</label>
                                        <timepicker  v-model="form.time_end" />
                                    </div>
                                </div>
                                <div class="col-md-12 form-group">
                                    <label class="control-label">Keterangan</label>
                                    <textarea class="form-control" v-model="form.keterangan" ></textarea>
                                </div>
                                <div class="col-md-12 form-group">
                                    <label class="control-label">Latihan / Tryout</label>
                                    <v-select :options="optUjian" v-model="form.ujian_id" :reduce="opt => opt.id" />
                                </div>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="card card-primary">
                                <div class="card-header">
                                <h3 class="card-title">FORM RELEASE MATERI</h3>
                                <div class="card-tools">
                                    <button type="button" class="btn btn-tool" data-card-widget="collapse" title="Collapse">
                                    <i class="fas fa-minus"></i>
                                    </button>
                                </div>
                                </div>
                                <div class="card-body" style="display: block;">
                        <div class="row">
                            <div class="col-md-12 form-group">
                            <label class="control-label">Materi Yang Dipilih</label>
                            <div v-for="row in showMateri" v-bind:key="row.id">
                                <input id="name" class="form-control" v-model="row.label" type="text" name="name" readonly />
                            </div>
                            </div>
                            <div class="col-md-12 form-group">
                                <label class="control-label">Range waktu (Tanggal Mulai & Tanggal Selesai)</label>
                                <input type="text" class="form-control" ref="daterange" id="daterange" autocomplete="off" value="">
                            </div>
                        </div>
                                </div>
                            </div>
                        </div>
                        </div>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-primary">Save changes</button>
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
    <!-- /.content -->
</template>
<script>
import { createTable, authFetch } from '@/libs/hxcore';
import $ from 'jquery';
window.JSZip = require('jszip')
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import vSelect from 'vue-select';
import "vue-select/dist/vue-select.css";
import datepicker from '@/components/Datepicker';
import timepicker from '@/components/Timepicker';
import Daterangepicker from 'daterangepicker';
import "daterangepicker/daterangepicker.css";
import moment from "moment";
import Swal from "sweetalert2";

export default {
    name: 'Userlist',
    components: {
        vSelect,
        timepicker,
        datepicker,
        Daterangepicker,
    },
    data() {
        return {
            errors: [],
            method: '',
            roles: '',
            groups: [],
            optMapel: [],
            optKelas: [],
            optTentor: [],
            optBranch: [],
            optProgram: [],
            optMateri: [],
            optUjian: [],
            showMateri: [],
            formTitle: 'Tambah',
            form: {
                name: '',
                username: '',
                email: '',
                gender: '',
                siswa: '',
                kelas: '',
                mapel_id: '',
                kelas_id: '',
                tentor_id: '',
                program_id: '',
                time_start: '',
                time_end: '',
                materi: '',
                keterangan: '',
                materi_id: '',
                tanggal_mulai: '',
                tanggal_selesai: '',
                ujian_id: ''
            }
        }
    },
    created: function() {
        this.roles = this.$route.meta.roles;
        authFetch('/akademik/jadwal_tentor/mapel')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.optMapel = js.data;
            })
        authFetch('/akademik/jadwal_tentor/kelas')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.optKelas = js.data;
            })
        authFetch('/akademik/jadwal_tentor/tentor')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.optTentor = js.data;
            })
        authFetch('/akademik/lesson_plan/cabang')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.optBranch = js.data;
            })
        authFetch('/akademik/lesson_plan/program')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.optProgram = js.data;
            })
    },
    methods: {
        setSelected(val){
            this.loadMateri(val);
            this.loadUjian()
        },
        setSelectedMateri(val){
            let materi = this.optMateri;
            let getMateri = materi.filter(function(arr, i) {
                return arr.id == val;
            });
            this.showMateri = getMateri;
        },
        loadMateri(mapel_id){
            this.form.materi_id = '';
            authFetch('/akademik/jadwal_tentor/mapel_materi?id='+mapel_id)
            .then(res => {
                if (res.status === 201) {
                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.optMateri = js.data;
                if (this.form.materi_id) {
                    let materi = this.optMateri;
                    var val = this.form.materi_id;
                    let getMateri = materi.filter(function(arr, i) {
                        return arr.id == val;
                    });
                    this.showMateri = getMateri;
                }
            })
        },
        loadUjian(program_id = '', mapel_id = ''){
            if (program_id) {
                authFetch('/akademik/lesson_plan/list_ujian/'+program_id+'/'+mapel_id)
                .then(res => {
                    if (res.status === 201) {

                    } else if (res.status === 400) {}
                    return res.json();
                })
                .then(js => {
                    this.optUjian = js.data;
                })
            } else {
                authFetch('/akademik/lesson_plan/list_ujian/'+this.form.program_id+'/'+this.form.mapel_id)
                .then(res => {
                    if (res.status === 201) {

                    } else if (res.status === 400) {}
                    return res.json();
                })
                .then(js => {
                    this.optUjian = js.data;
                })
            }
        },
        handleClick(e) {
            if (e.target.matches('.link-role')) {
                this.$router.push({ path: '/permit/' + e.target.dataset.id })
                return false;
            }
        },
        search: function() {
            this.table.api().ajax.reload();
        },
        submitForm: function(ev) {
            const e = this.$refs;
            var data = Object.keys(this.form).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(this.form[key])).join('&')
            var urlSubmit = '/akademik/lesson_plan';
            if (this.method == 'PUT') urlSubmit = '/akademik/lesson_plan/' + this.form.id;

            authFetch(urlSubmit, {
                    method: this.method,
                    body: data
                })
                .then(res => {
                    if (res.status === 201) {

                    } else if (res.status === 400) {}
                    return res.json();
                })
                .then(js => {

                    this.errors = [];
                    if (!js.success) {
                        console.log(js.details)

                        for (var key in js.details) {
                            if (js.details.hasOwnProperty(key)) {
                                this.errors.push(js.details[key])
                            }
                        }
                        Swal.fire({
                            icon: 'info',
                            title: 'Informasi',
                            text: 'Data tidak tersimpan!'
                        })
                        return;
                    }
                    Swal.fire({
                        icon: 'success',
                        title: 'Informasi',
                        text: 'Data tersimpan!'
                    })
                    this.table.api().ajax.reload();
                    $(e.formDialog).modal('hide');
                });

            ev.preventDefault();
        }
    },
    mounted() {
        const e = this.$refs;
        var self = this;

        new Daterangepicker(this.$refs.daterange, {
            startDate: moment(),
            endDate: moment().add(1,"days"),
            "drops": "up",
            locale: {
                format: 'DD/MM/YYYY'
            },
            }, function(d1, d2) {
                self.form.tanggal_mulai = d1.format('DD/MM/YYYY')
                self.form.tanggal_selesai = d2.format('DD/MM/YYYY')
            });

        this.table = createTable(e.tableuser, {
            "title": "List plan mengajar",
            "roles": this.$route.params.roles,
            "ajax": "/akademik/lesson_plan",
            scrollX: true,
            "columns": [
                { "data": "code" },
                { "data": "tentor" },
                { "data": "mapel" },
                { "data": "kelas" },
                { "data": "code" },
                { "data": "date" },
                { "data": "time_start" },
                { "data": "materi" },
                { "data": "ujian_title" },
                { "data": "keterangan" },
            ],
            filter:false,
            "rowCallback": function(row, data) {
                var namahari = ("Minggu Senin Selasa Rabu Kamis Jumat Sabtu");
                namahari = namahari.split(" ");
                var tgl = new Date(data.date);
                var hari = tgl.getDay();
                $('td:eq(4)', row).html(namahari[hari]);
                var time_start = data.time_start ? data.time_start.substring(0,5) : '';
                var time_end = data.time_end ? data.time_end.substring(0,5) : '';
                $('td:eq(6)', row).html(time_start+' - '+time_end);
                $('td:eq(9)', row).html(data.keterangan && data.keterangan != 'null' ? data.keterangan : '');
            },
            "paramData": function ( d ) {
                d.mapel = $('.mapel').val();
                d.kelas = $('.kelas').val();
                d.materi = $('.materi').val();
                d.tentor = $('.tentor').val();
            },
            buttonClick: (evt) => {
                if (evt.role == 'create') {
                    self.form = {};
                    self.showMateri = [];
                    new Daterangepicker(this.$refs.daterange, {
                    startDate: moment(),
                    endDate: moment().add(1,"days"),
                    locale: {
                        format: 'DD/MM/YYYY'
                    },
                    }, function(d1, d2) {
                        self.form.tanggal_mulai = d1.format('DD/MM/YYYY')
                        self.form.tanggal_selesai = d2.format('DD/MM/YYYY')
                    });

                    self.method = 'POST';
                    self.errors = [];
                    self.formTitle = 'Tambah Plan Mengajar';
                    $(e.formDialog).modal('show');
                } else if (evt.role == 'update' && evt.data) {
                    // self.form = evt.data;
                    this.loadMateri(evt.data.mapel_id)
                    this.loadUjian(evt.data.program_id, evt.data.mapel_id)
                    this.setSelectedMateri(evt.data.materi_id)
                    self.form.id = evt.data.id;
                    self.form.name = evt.data.name;
                    self.form.username = evt.data.username;
                    self.form.email = evt.data.email;
                    self.form.gender = evt.data.gender;
                    self.form.siswa = evt.data.siswa;
                    self.form.kelas = evt.data.kelas;
                    self.form.mapel_id = evt.data.mapel_id;
                    self.form.kelas_id = evt.data.kelas_id;
                    self.form.tentor_id = evt.data.tentor_id;
                    self.form.program_id = evt.data.program_id;
                    self.form.time_start = evt.data.time_start;
                    self.form.time_end = evt.data.time_end;
                    self.form.materi = evt.data.materi;
                    self.form.keterangan = evt.data.keterangan;
                    self.form.materi_id = evt.data.materi_id;
                    self.form.tanggal_mulai = evt.data.tanggal_mulai;
                    self.form.tanggal_selesai = evt.data.tanggal_selesai;
                    self.form.ujian_id = evt.data.ujian_id;
                    
                    var namahari = ("Minggu Senin Selasa Rabu Kamis Jumat Sabtu");
                    namahari = namahari.split(" ");
                    var tgl = new Date(evt.data.date);
                    var hari = tgl.getDay();

                    self.form.pukul = evt.data.time_start;
                    self.form.tanggal = tgl.getDate()+'/'+(tgl.getMonth()+1)+'/'+tgl.getFullYear();
                    self.form.hari = namahari[hari];

                    let dt1 = !evt.data.tanggal_mulai ? moment() : moment(evt.data.tanggal_mulai);
                    let dt2 = !evt.data.tanggal_selesai ? moment().add(1,"days") : moment(evt.data.tanggal_selesai);
                    new Daterangepicker(this.$refs.daterange, {
                    startDate: dt1,
                    endDate: dt2,
                    locale: {
                        format: 'DD/MM/YYYY'
                    },
                    }, function(d1, d2) {
                        self.form.tanggal_mulai = d1.format('DD/MM/YYYY')
                        self.form.tanggal_selesai = d2.format('DD/MM/YYYY')
                    });

                    self.method = 'PUT';
                    self.errors = [];
                    self.formTitle = 'Edit Plan Mengajar';
                    $(e.formDialog).modal('show');
                } else if (evt.role == 'delete' && evt.data) {
                    self.form = evt.data;
                    authFetch('/akademik/lesson_plan/' + evt.data.id, {
                            method: 'DELETE',
                            body: 'id=' + evt.data.id
                        })
                        .then(res => {
                            return res.json();
                        })
                        .then(js => {
                            this.table.api().ajax.reload();
                        });
                }

            },
            dom: "<'row'<'col-sm-12 col-md-6 mb-2'B>><'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>><'row'<'col-sm-12'tr>><'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
            buttons: [
                {
                    extend: 'excel',
                    text: 'XLSX',
                    className: 'btn btn-success',
                    exportOptions: {
                        columns:[0,1,2,3,4,5,6,7,8,9],
                    },
                    title: function(){
                        return 'Lesson Plan';
                    },
                    filename: function(){
                        return 'Lesson Plan';
                    },
                },
                {
                    extend: 'print',
                    text: 'Print',
                    className: 'btn btn-primary',
                    exportOptions: {
                        columns:[0,1,2,3,4,5,6,7,8,9],
                    },
                    orientation: 'landscape',
                    title: function(){
                        return 'Lesson Plan';
                    },
                    filename: function(){
                        return 'Lesson Plan';
                    },
                },
                {
                    extend: 'pdf',
                    text: 'PDF',
                    className: 'btn btn-info',
                    exportOptions: {
                        columns:[0,1,2,3,4,5,6,7,8,9],
                    },
                    orientation: 'landscape',
                    title: function(){
                        return 'Lesson Plan';
                    },
                    filename: function(){
                        return 'Lesson Plan';
                    },
                },
                {
                    extend: 'csvHtml5',
                    text: 'CSV',
                    className: 'btn bg-gray-dark',
                    exportOptions: {
                        columns:[0,1,2,3,4,5,6,7,8,9],
                    },
                    title: function(){
                        return 'Lesson Plan';
                    },
                    filename: function(){
                        return 'Lesson Plan';
                    },
                },
            ],
        });
    }
}
</script>
<style scoped>
th {
  text-transform: uppercase;
}
</style>